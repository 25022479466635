<template>
    <div>
        <!--Service section start-->
        <div class="secoes_site">     
            <div>
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <div class="breadcrumb">
                                <h1>Serviço: {{detalhe_produto.produtos.titulo}}</h1>
                                <!-- <b-breadcrumb class="page-breadcrumb" :items="items"></b-breadcrumb> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="service-section">
            <div class="container">
                <div class="services-details-area">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div>
                            <div class="details-img mb-40">
                              <div class="row">
                                <div class="col-4">
                                <img :src="'https://sistema.gouarts.com/uploads/' + detalhe_produto.produtos.foto" class="img-fluid" :alt="detalhe_produto.produtos.titulo">
                                </div>
                                <div class="col-8">
                                    <div class="details-caption">
                                        <h3>{{detalhe_produto.produtos.titulo}}</h3>
                                        <strong>{{detalhe_produto.produtos.resumo}}</strong>
                                        <p v-html="detalhe_produto.produtos.conteudo">{{detalhe_produto.produtos.conteudo}}</p>                                    
                                </div>
                                </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
            </div>
        </div>
        <!--Service section end-->
    </div>
</template>

<script>
    import axios from 'axios';
    
    export default {
        created(){
            doSomething.call(this);
        },
        watch: {
        '$route' () {
            doSomething.call(this);
            }
        },
        
        data () {
            return {
                detalhe_produto: []               
            }
        },
         
    };

    function doSomething() {
        var id = this.$route.params.id;   
        var slug = this.$route.params.slug;
        axios.get(`https://sistema.gouarts.com/api/produto/`+id+`/`+slug+`?cliente=vitoriainspecoes`).then(res => {
            this.detalhe_produto = res.data;
        }).catch(err => {
            console.log(err);
        })
}
</script>

<style scoped>
</style>
