<template>
    <div class="main-container">

        <Header />

        <!-- <Breadcrumb :items="items" title="Serviços" /> -->

        <ProdutoDetalhe />
       
        <Footer />

        <!-- <OffCanvasMobileMenu /> -->

        <!-- back to top start -->
        <back-to-top class="scroll-top" bottom="60px">
            <i class="ion-android-arrow-up"></i>
        </back-to-top>
        <!-- back to top end -->
    </div>
</template>

<script>
    import Header from '@/components/Header';
    // import Breadcrumb from '../components/Breadcrumb'
    import ProdutoDetalhe from '../components/sections/ProdutoDetalhe'
    import Footer from '../components/Footer'
    // import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu';
    export default {
        components: {
            Header,
            // Breadcrumb,
            ProdutoDetalhe,
            Footer,
            //OffCanvasMobileMenu
        },
        data() {
            return {
                items: [
                    {
                        text: 'Home',
                        to: "/"
                    },
                    {
                        text: 'Detalhes',
                        active: true
                    }
                ],                
            }
        },
        metaInfo: {
            title: 'Vix Inpeções',
            titleTemplate: `%s - produtos`,
            meta: [{
            name: 'description',
            content: 'Vix Inpeções'
            },
            {
            name: 'keywords',
            content: 'Vix Inspeções, inspenções, vistoria, vistorias de veículos escolares, veículos pesados, veículos de passeios'
            }
            ],
            htmlAttrs: {
                lang: 'pt-BR',
                amp: true
            }
        }
    }
</script>

